<template>
  <div>
    <el-card class="box-card">
      <div class="search-table-box">
        <div>
          <el-form ref="form" :inline="true" :model="queryParams" label-width="80px">
            <el-form-item label="关键字">
              <el-input class="w230" clearable v-model="queryParams.searchName" placeholder="志愿者姓名/手机号">
              </el-input>
            </el-form-item>
            <el-form-item label="积分变动">
              <el-select v-model="queryParams.integralOperation" placeholder="请选择" class="w230" clearable>
                <el-option :label="item.name" :value="item.val" v-for="item in integralOperateList" :key="item.val">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchList">查询</el-button>
            </el-form-item>
            <div class="down-export">
              <!-- <el-button type="primary" @click="add()">新增</el-button> -->
              <el-button type="primary" @click="downloadTemp()">模板下载</el-button>
              <el-button type="primary" @click="uploadVisible = true">导入</el-button>
              <el-button type="primary" plain @click="downLoad">导出</el-button>
            </div>
          </el-form>
        </div>
        <el-table v-loading="loading" style="width: 100%; margin-top: 10px" :data="tableData" border
          :header-cell-style="headerCellStyle">
          <template slot="empty">
            <IsEmpty />
          </template>
          <el-table-column type="index" label="序号" width="50" align="center" />
          <el-table-column prop="volunteerName" label="姓名" align="center" />
          <!-- <el-table-column prop="groupName" label="手机号" align="center" /> -->
          <el-table-column prop="systemName" label="积分体系" align="center" />
          <el-table-column prop="groupName" label="志愿团体" align="center" />
          <el-table-column prop="integralOperation" label="变动积分"  align="center" />
          <el-table-column prop="integralChange" label="变动积分数" align="center" />
          <el-table-column prop="description" label="变动原因" width="250" align="center" />
          <el-table-column prop="operationAccount" label="操作账号" align="center" />
          <el-table-column prop="createTime" label="操作时间" align="center" />
          <el-table-column label="操作" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="scoreRecord(scope.row)">积分记录</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryParams.pageNum" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.pageSize"
          layout="total, sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>
    </el-card>
    <!-- 上传 -->
    <UploadFile url="/integralRecord/importVolunteerRecordList" :uploadVisible.sync="uploadVisible"
      @updateList="searchList" />
  </div>
</template>

<script>
import UploadFile from '@/components/uploadFile'
import { downloadStream } from '@/utils/index'
import {
  getVolunteerIntegralRecordList,
  downloadVolunteerRecordTemplate,
  exportVolunteerRecordList
} from '@/api/integralRecord'
export default {
  components: {
    UploadFile
  },
  data() {
    return {
      integralOperateList: [
        { name: '增加', val: 1 },
        { name: '减少', val: 2 },
        { name: '转让', val: 3 },
        { name: '兑换', val: 4 }
      ],
      uploadVisible: false,
      loading: false,
      tableData: [],
      queryParams: {
        //查询参数
        pageNum: 1,
        pageSize: 10,
        searchName: '',
        integralOperation: ''
      },
      total: 0
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    add() {
      this.$router.push({
        path: '/volunteer/volunteerManage/add',
        query: { type: 1 }
      })
    },
    edit(row) {
      this.$router.push({
        path: '/volunteer/volunteerManage/add',
        query: { info: JSON.stringify(row), type: 2 }
      })
    },
    toDetail(row) {
      this.$router.push({
        path: '/volunteer/volunteerManage/add',
        query: { info: JSON.stringify(row), type: 3 }
      })
    },
    scoreRecord(row) {
      this.$router.push({
        path: '/volunteer/integralManage/integralRecord',
        query: { volunteerId: row.id,volunteerName: row.volunteerName,idNumber: row.idNumber }
      })
    },
    async remove(i, row, rows) {
      console.log(row)
      const res = await deleteVolunteer({ id: row.id })
      rows.splice(i, 1)
      // row.status === 1 ? (row.status = 0) : (row.status = 1)
      this.$message.success(res.data)
    },
    removeDialog(i, row, rows) {
      this.$confirm(`是否删除${row.volunteerName}这名志愿者`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.remove(i, row, rows)
      })
    },
    downloadTemp() {
      downloadVolunteerRecordTemplate().then((res) => {
        downloadStream(res, '积分管理模板.xlsx')
      })
    },
    async downLoad() {
      const res = await exportVolunteerRecordList(this.queryParams)
      downloadStream(res, '积分管理表.xlsx')
    },
    async getList() {
      this.loading = true
      const res = await getVolunteerIntegralRecordList(this.queryParams)
      this.loading = false
      this.tableData = res.data.rows
      this.total = res.data.total
    },
    searchList() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.queryParams.pageSize = pageSize
      this.queryParams.pageNum = 1
      this.getList()
    },
    handleCurrentChange(pageNum) {
      this.queryParams.pageNum = pageNum
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.box-card {
  min-height: calc(100% - 10px);
}
.red {
  color: rgb(255, 90, 89);
}
.search-table-box {
  .down-export {
    float: right;
    margin-bottom: 20px;
    display: flex;
    button {
      margin-left: 10px;
    }
    button:first-child {
      margin: 0 10px;
    }
  }
}
/deep/ .upload-demo {
  text-align: center;
}
</style>
